import React from 'react';
import {
  NewsElement,
  NewsContainer,
  NewsOutsideWrapper,
  NewsImage,
  NewsContent,
  NewsTitle,
  NewsDescription,
  NewsBottomContent,
  NewsSpan,
  NewsDate,
  NewsMainTitle,
  NewsRight,
  NewsLeft,
  NewsReadMore
} from './News.styles';
import { IoMdTime } from "react-icons/io";
import renaultTruckImg from "../../Assets/Images/renaultTruck.png"
import secondNews from "../../Assets/Images/newsImage2.png"
import thirdNewsImg from "../../Assets/Images/newsImage3.png"

const News = () => {
  return (
    <>
      <NewsOutsideWrapper>
        <NewsMainTitle>News</NewsMainTitle>
        <NewsContainer>

        <NewsElement href="https://www.agg-net.com/news/further-expansion-plans-for-inert-recycling" target="_blank">
            <NewsImage imageurl={secondNews} />
            <NewsContent>
              <NewsTitle>
                Further expansion plans for Inert Recycling
              </NewsTitle>
              <NewsDescription>
              Inert Recycling will be looking to expand their contracting and service offerings in Sussex as they have done at Cemex’s Sandgate Quarry
              </NewsDescription>
              <NewsBottomContent>
                <NewsRight>
                <NewsSpan>News</NewsSpan>
                <NewsDate>
                  <IoMdTime />
                  02/04/2024
                </NewsDate>
                </NewsRight>
                <NewsLeft>
                    <NewsReadMore>Read More</NewsReadMore>
                </NewsLeft>
              </NewsBottomContent>
            </NewsContent>
          </NewsElement>
          
          <NewsElement href="https://www.renault-trucks.co.uk/press-release/inert-recycling-uk-limited-selects-renault-trucks-tippers-for-toughest-tasks" target="_blank">
            <NewsImage imageurl={renaultTruckImg} />
            <NewsContent>
              <NewsTitle>
                Inert Recycling adds eight new Renault Truck tippers to fleet
              </NewsTitle>
              <NewsDescription>
                Inert Recycling (UK) Limited selects Renault Trucks tippers for toughest tasks
              </NewsDescription>
              <NewsBottomContent>
                <NewsRight>
                <NewsSpan>News</NewsSpan>
                <NewsDate>
                  <IoMdTime />
                  28/04/2023
                </NewsDate>
                </NewsRight>
                <NewsLeft>
                    <NewsReadMore>Read More</NewsReadMore>
                </NewsLeft>
                
              </NewsBottomContent>
            </NewsContent>
          </NewsElement>
          

          <NewsElement href="https://www.pitchero.com/clubs/uphamfc/news/inert-recycling-uk-ltd--new-sponsor-for-202021-season-2710714.html" target="_blank">
            <NewsImage imageurl={thirdNewsImg}/>
            <NewsContent>
              <NewsTitle>
                Inert Recycling UK Ltd - Sponsor for 2022-23 season
              </NewsTitle>
              <NewsDescription>
              </NewsDescription>
              <NewsBottomContent>
               
                <NewsRight>
                <NewsSpan>News</NewsSpan>
                <NewsDate>
                  <IoMdTime />
                  27/06/2022
                </NewsDate>
                </NewsRight>
                <NewsLeft>
                    <NewsReadMore>Read More</NewsReadMore>
                </NewsLeft>


              </NewsBottomContent>
            </NewsContent>
          </NewsElement>
          
        </NewsContainer>
      </NewsOutsideWrapper>
    </>
  );
};

export default News;
