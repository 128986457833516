import React, { useEffect } from "react";
import { TileLayer, Marker, Popup, useMap, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet";
import { MapWrapper, StyledMapContainer } from "./map.styles";

// Fix marker icons (Leaflet default icons may not load correctly)
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapResize = () => {
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
  }, [map]);

  return null;
};

const MyMapComponent = () => {
  const position = [50.9919158, -1.3388749];
  const radius = 2500;

  return (
    <MapWrapper>
      <StyledMapContainer center={position} zoom={13} scrollWheelZoom={false}>
        <MapResize />
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position}>
          <Popup>Inert Recycling (UK)</Popup>
        </Marker>
        <Circle center={position} radius={radius} pathOptions={{ color: "blue", fillColor: "lightblue", fillOpacity: 0.6 }} />
      </StyledMapContainer>
    </MapWrapper>
  );
};

export default MyMapComponent;
