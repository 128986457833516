import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  color: white;

  .OutsideWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .NavigationContainer {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    @media (min-width: 768px) {
      display: flex !important;
    }
  }
`;

export const Logo = styled.a`
  font-size: 1.5rem;
  color: white;
  margin: 0;
  cursor: pointer;
  img {
    height: 50px;
  }
  @media (min-width: 1023px) {
    img {
      height: 70px;
    }
  }
`;

export const Nav = styled.nav`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 767px) {
    display: none; /* Hide nav links on mobile */
  }
`;

export const NavLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
  line-height: 40px;

  &:hover {
    color: ${({ theme }) => theme.colors.body};
  }
`;

export const HamburgerIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: none;

  @media (max-width: 767px) {
    display: block; 
  }
`;

export const MobileMenu = styled.div`
  position: fixed;
  top: 72px;
  right: 0;
  width: 100%;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 1000;
  padding: 2rem;
  border-top: 6px solid ${({ theme }) => theme.colors.primary};
  border-bottom: 6px solid ${({ theme }) => theme.colors.primary};

  @media (min-width: 769px) {
    display: none;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
`;

export const ContactNumber = styled.a`
  color: white;
  margin-right: -20%;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const ContactNumberDesktop = styled.a`
  color: white;
  display: none;
  font-size: 19px;
  @media (min-width: 768px) {
    display: block;

    &:hover {
    color: ${({ theme }) => theme.colors.primary}!important;
  }
  }
`;

export const DesktopNav = styled.div`
  height: 60px;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 2rem;
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

export const DesktopLink = styled.a`
  color: ${({ theme }) => theme.colors.darkBackground};
  text-transform: uppercase;
  border-bottom: none;
  //font-size: 1.2rem;
  font-weight: 700;

  &:hover {
    color: ${({ theme }) => theme.colors.body};
  }
`;
