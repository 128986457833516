import React, { useState, useEffect } from "react";
import {  
  MachineryContainer, 
  MachineryWrapper, 
  MachineryInformationWrapper, 
  MachineryInformation, 
  MachineryTitle, 
  TriangleText,
  CustomSlider
} from "./machinery.styles";
import { IoMdClose } from "react-icons/io";

import excavatorImage from "../../Assets/Images/IMG_3740.jpeg";
import dozer from '../../Assets/Images/dozer.jpg';
import mec from '../../Assets/Images/crusher_1.jpeg'
import screener from '../../Assets/Images/crusher.jpeg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import excavator1 from '../../Assets/Images/mainImage1.jpg'
import excavator2 from '../../Assets/Images/excavator2.jpeg'
import excavator3 from '../../Assets/Images/excavator3.jpeg'
import dozer2 from '../../Assets/Images/dozer2.JPG'
import dozer3 from '../../Assets/Images/dozer_1.JPG'
import dozer4 from '../../Assets/Images/dozer_2.jpg'
import crusher from '../../Assets/Images/crusher.jpeg'
import excavator4 from '../../Assets/Images/excavator_1.jpg'
import excavator5 from '../../Assets/Images/excavator_2.jpg'
import excavator6 from '../../Assets/Images/excavator_3.jpg'
import excavator7 from '../../Assets/Images/excavator_4.jpg'
import excavator8 from '../../Assets/Images/excavator_5.jpg'
import crusher1 from '../../Assets/Images/crusher_2.JPG'
import crusher2 from '../../Assets/Images/crusher_4.JPG'
import crusher3 from '../../Assets/Images/crusher_5.JPG'
import crusher4 from '../../Assets/Images/crusher_6.jpeg'


const Modal = ({ show, onClose, data }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"; 
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  if (!show) return null;

  const handleBackdropClick = (e) => {
    if (e.target.id === "modal-backdrop") {
      onClose();
    }
  };
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const modalStyle = {
    background: "#fff", 
    padding: "20px", 
    borderRadius: "8px", 
    textAlign: "center",
    position: "relative",
    boxShadow: "0px 5px 15px rgba(0,0,0,0.3)",
    maxWidth: isMobile ? "340px" : "550px", 
    height: isMobile ? "auto" : "auto",
  };

  return (
    <div 
      id="modal-backdrop"
      onClick={handleBackdropClick}
      style={{
        position: "fixed", 
        top: 0, 
        left: 0, 
        width: "100%", 
        height: "100%", 
        background: "rgba(0,0,0,0.5)", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center",
        zIndex: 1000
      }}
    >
      <div style={modalStyle}>
        <button 
          onClick={onClose} 
          style={{
            position: "absolute",
            top: "10px",
            right: "15px",
            background: "transparent",
            border: "none",
            fontSize: "20px",
            cursor: "pointer"
          }}
        >
         <IoMdClose />
        </button>
        <button 
          onClick={onClose} 
          style={{
            position: "absolute",
            top: "10px",
            right: "15px",
            background: "transparent",
            border: "none",
            fontSize: "20px",
            cursor: "pointer"
          }}
        >
         <IoMdClose />
        </button>

        <h2>{data.title}</h2>
        <CustomSlider {...sliderSettings}>
    {data.images?.map((image, index) => (
      <div key={index}>
        <img src={image} alt={`${data.title} ${index + 1}`} style={{ width: "100%", borderRadius: "8px" }} />
      </div>
    ))}
  </CustomSlider>
        <p>{data.description}</p>
      </div>
    </div>
  );
};

const Machinery = () => {
  const [selectedMachinery, setSelectedMachinery] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const machineryData = {
    excavators: {
      title: "Excavators",
      images: [excavator3, excavator2, excavator1, excavatorImage, excavator4, excavator5, excavator6, excavator7,excavator8],
      description: "Excavators are versatile heavy machinery used for digging, trenching, material handling, demolition, and site preparation. Equipped with a powerful hydraulic system and various attachments, they are essential for construction, mining, landscaping, and infrastructure projects, enabling efficient earthmoving, lifting, and debris removal."
    },
    screeners: {
      title: "Screeners",
      images: [screener,screener],
      description: "Screeners are machines designed to separate materials into different sizes, ensuring efficient sorting of aggregates, soil, sand, and other materials for construction and processing."
    },
    dozers: {
      title: "Dozers",
      images: [dozer, dozer2,dozer3, dozer4],
      description: "Dozers are powerful, tracked machines designed for pushing, leveling, and grading large quantities of material. Equipped with a broad, durable blade, they excel at clearing land, shaping terrain, and handling heavy-duty earthmoving tasks."
    },
    crusher: {
      title: "Crusher",
      images: [mec, crusher,crusher1,crusher2,crusher3,crusher4],
      description: "Crushers are powerful machines designed to break down rocks, concrete, and other materials into smaller, usable sizes. They are essential for producing aggregates used in construction, road building, and other heavy-duty applications."
    }
  };

  const handleMachineryClick = (key) => {
    setSelectedMachinery(machineryData[key]);
    setModalOpen(true);
  };

  return (
    <MachineryContainer>
      <MachineryTitle>Plant Machinery</MachineryTitle>
      <MachineryWrapper>
        <MachineryInformationWrapper>
          <MachineryInformation onClick={() => handleMachineryClick("excavators")} background={excavatorImage}>
            <TriangleText>Excavators</TriangleText>
          </MachineryInformation>
          <MachineryInformation onClick={() => handleMachineryClick("screeners")} background={screener}>
            <TriangleText>Screeners</TriangleText>
          </MachineryInformation>
          <MachineryInformation onClick={() => handleMachineryClick("dozers")} background={dozer}>
            <TriangleText>Dozers</TriangleText>
          </MachineryInformation>
          <MachineryInformation onClick={() => handleMachineryClick("crusher")} background={mec}>
            <TriangleText>Crusher</TriangleText>
          </MachineryInformation>
        </MachineryInformationWrapper>
      </MachineryWrapper>

      <Modal show={modalOpen} onClose={() => setModalOpen(false)} data={selectedMachinery || {}} />
    </MachineryContainer>
  );
};

export default Machinery;
