import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  /* Reset CSS */
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    line-height: 1.6;
    font-size: 16px;
  }

  /* Basic styles for headings and paragraphs */
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    transition: color 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  ul {
    list-style: none;
  }

  /* Images and media */
  img {
    max-width: 100%;
    display: block;
  }

  /* Utility classes */
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }

  .text-center {
    text-align: center;
  }

  .m-1 { margin: 1rem; }
  .m-2 { margin: 2rem; }
  .p-1 { padding: 1rem; }
  .p-2 { padding: 2rem; }

  /* Responsive Typography */
  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.colors.primary};
  }

  /* Swiper Pagination Bullets */
  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.colors.primary};
  }

  /* Active Bullet */
  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.primary};
  }
  .lineBreak{
    display: block;
    width: 85px;
    background: ${({ theme }) => theme.colors.primary};;
    height: 2px;
    margin-bottom: 20px;
  }

`;

