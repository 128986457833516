import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Wrapper, FirstContentWrapper, StyledImage, StyledSwiper, OverlayContainer, OverlayText, OverlayTextDescription, SegmentButton } from "./firstContent.styles";
import mainImage from "../../Assets/Images/mainImage1.jpg"
import secondImage from '../../Assets/Images/mainImage2.JPG'

const FirstContent = () => {
  const images = [mainImage, secondImage];

  const texts = ["Machinery for Every Task, No Matter the Size! ", "On the Move for Your Project Needs!", "TRL"];

  const textDescriptions = ["Your Mission, Our Machinery.", "From Small Projects to Big Ambitions, We've Got You Covered."];
  const buttonText = ["About us", "See more","Visit Website"];
  const buttonHref = ["/about", "/#","/visitWebsite"];

  return (
    <Wrapper>
      <FirstContentWrapper>
        <StyledSwiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 9000,
            disableOnInteraction: false,
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <OverlayContainer>
                <StyledImage src={image} alt={`Slide ${index + 1}`} />
                <div className="textContentWrapper">
                  <OverlayText>{texts[index]}</OverlayText>
                  <OverlayTextDescription>{textDescriptions[index]}</OverlayTextDescription>
                  {buttonText[index] !== "See more" && (
                    <SegmentButton href={buttonHref[index]}>{buttonText[index]}</SegmentButton>)}
                </div>
              </OverlayContainer>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </FirstContentWrapper>
    </Wrapper>
  );
};

export default FirstContent;
