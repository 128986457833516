import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./Styles/GlobalStyles";
import theme from "./Styles/Theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import FirstContent from "./Components/FirstContent/firstContent.jsx";
import HealthAndSafety from "./Components/Health_and_Safety/health_and_safety.jsx";
import Segment from "./Components/AboutSegment/segment.jsx";
import Machinery from "./Components/Machinery/machinery.jsx";
import MyMapComponent from "./Components/Map/map.jsx";
import ContactUs from "./Components/Contact/contact.jsx";
import tipperImage from "../src/Assets/Images/tipper.jpg";
import ImageContent from "../src/Components/ImageGallery/Images.jsx";
import Hire from "./Components/HireSegment/hire.jsx";
import TimeLine from "./Components/TimeLine/timeLine.jsx";
import Partners from "./Components/Partners/Partners.jsx";
import News from './Components/News/News.jsx'
import HealthAndSafetyPage from "./Components/Health_and_SafetyPage/Health_and_SafetyPage.jsx";
import truckTipping from './Assets/Images/truckTIpping.jpg'
import MachineryTypes from './Components/MachineryTypes/machineryTypes.jsx'
import Excavator from './Assets/Images/excavators1.JPG'
import CookieConsent from "react-cookie-consent";
import CookiePolicy from "./Components/CookieConsent/Cookies.jsx"

function App() {
  const infoCardsTrucks = [
    { imageSrc: Excavator, altText: "Excavators Img", text: "Excavators" },
    { imageSrc: "/path-to-image2.jpg", altText: "Image 2", text: "Dump Trucks" },
    { imageSrc: "/path-to-image3.jpg", altText: "Image 3", text: "Cranes" },
  ];

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Header />
        
        <Routes>
          <Route
            path="/"
            element={
              <>
                <FirstContent />
                <Segment />
                <Machinery />
                <MyMapComponent />
                <HealthAndSafety />
              </>
            }
          />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route
            path="/tipper"
            element={
              <>
                <Hire Tipper="Tipper Hire" backgroundimage={tipperImage} textContent="Reliable Tipper Hire for Every Job – Moving Materials Made Easy" />
                <ImageContent
                  InfoText="Whatever the scale of your project, our fleet of tippers is ready to support you. From smaller trucks for confined spaces to large, heavy-duty models for bulk loads, each vehicle in our lineup is chosen to provide you with maximum efficiency and reliability. Our team ensures every truck is maintained to the highest standards, so whether you're clearing a site, moving materials, or managing waste, we’ll keep your project moving smoothly, on time, and on budget."
                  TitleText= "Tipper Hire"
                />
              </>
            }
          />
          <Route
            path="/plant"
            element={
              <>
                <Hire Tipper="Plant Hire" backgroundimage={tipperImage} textContent="Reliable Tipper Hire for Every Job – Moving Materials Made Easy" />
                <ImageContent 
                  InfoText="Whatever the demands of your project, our extensive range of plant hire equipment is here to deliver exceptional performance and reliability. From compact excavators for precision tasks to powerful machinery for large-scale operations, our fleet is designed to meet the challenges of any site. With equipment maintained to the highest standards and backed by expert support, we ensure your projects run efficiently, safely, and on schedule. Whether you're preparing a foundation, managing heavy loads, or tackling specialized tasks, our plant hire solutions have you covered every step of the way."
                  TitleText= "Plant Hire "
                />
              </>
            }
          />
          <Route
            path="/landRestoration"
            element={
              <>
                <Hire Tipper="Land Restoration" backgroundimage={tipperImage} textContent="Reliable Tipper Hire for Every Job – Moving Materials Made Easy" />
                <ImageContent 
                  InfoText="Transforming landscapes is at the heart of our land restoration services. Whether you're rehabilitating degraded land, preparing a site for redevelopment, or enhancing natural ecosystems, our specialized equipment and expert team are ready to support your vision. From soil stabilization to large-scale earthmoving, we offer tailored solutions to meet the unique challenges of your project. With a commitment to environmental sustainability and precision, we ensure your land restoration efforts are efficient, impactful, and aligned with your goals for a greener, healthier future."
                  TitleText= "Land Restoration Hire "
                />
              </>
            }
          />
          <Route
            path="/landfill"
            element={
              <>
                <Hire Tipper="Landfill" backgroundimage={tipperImage} textContent="Reliable Tipper Hire for Every Job – Moving Materials Made Easy" />
                <ImageContent 
                  InfoText="Our landfill services are designed to provide efficient, safe, and environmentally responsible waste management solutions. Whether you are managing construction debris, industrial waste, or general refuse, our team has the expertise and equipment to handle all types of landfill operations. We ensure proper waste disposal practices, adherence to regulations, and minimize environmental impact. From site preparation to waste compaction and capping, we work with you to maintain a sustainable and compliant landfill operation, helping you manage waste responsibly while contributing to a cleaner, more organized environment."
                  TitleText= "Land Restoration Hire "
                />
              </>
            }
          />
          <Route
            path="/wasteplanning"
            element={
              <>
                <Hire Tipper="Waste planning" backgroundimage={tipperImage} textContent="Reliable Tipper Hire for Every Job – Moving Materials Made Easy" />
                <ImageContent 
                  InfoText="Effective waste planning is key to managing resources sustainably and minimizing environmental impact. Our waste planning services provide comprehensive strategies tailored to your project’s needs, from waste reduction to efficient disposal methods. We help you design and implement waste management systems that comply with regulations, optimize resource use, and reduce landfill waste. Whether you're handling construction, industrial, or commercial waste, our expert team ensures your waste is managed responsibly, minimizing environmental harm and supporting your sustainability goals from start to finish."
                  TitleText= "Waste planning "
                />
              </>
            }
          />

          <Route
            path="/contact"
            element={
              <>
                <ContactUs />
              </>
            }
          />
          <Route
            path="/health-safety"
            element={
              <>
                <HealthAndSafetyPage backgroundimage={truckTipping}  />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <TimeLine />
              </>
            }
          />
          <Route
            path="/news"
            element={
              <>
                <News />
              </>
            }
          />
          <Route
            path="/trucks"
            element={
              <>
                <MachineryTypes
                  imageSrc={Excavator}
                  altText="Excavator-model-98363"
                  title="Excavators"
                  description="Excavators are used for digging and heavy lifting tasks."
                  infoCards={infoCardsTrucks}
                />
              </>
            }
          />
          <Route
            path="/excavators"
            element={
              <>
                <News />
              </>
            }
          />
          <Route
            path="/buldozer"
            element={
              <>
                <News />
              </>
            }
          />
          <Route
            path="/trucks"
            element={
              <>
                <News />
              </>
            }
          />
        </Routes>

        <Footer />
      </Router>
      <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton={true}
      cookieName="myCookieConsent"
      style={{ background: "#0a0a0a", color: "#ffffff", fontSize: '13px', zIndex: '9999' }}
      buttonStyle={{ background: "rgb(171, 198, 41)", color: "#0a0a0a" }}
    >
     We use cookies to enhance your browsing experience, serve personalized content, and analyze site traffic. By clicking 'Accept', you consent to our use of cookies.
    </CookieConsent>
    </ThemeProvider>
  );
}

export default App;
