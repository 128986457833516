import styled from "styled-components";

export const SegmentContainer = styled.div`
  background-color: #e6e6e6;
  width: 100%;
  height: auto;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
`;
export const SegmentWrapper = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const SegmentTitle = styled.h1``;
export const SegmentText = styled.p``;
export const SegmentButton = styled.a`
  width: auto;
  margin: 0 auto;
  font-weight: 700;
  background: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.darkBackground};
  padding: 10px 25px;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.body};
  }
`;

