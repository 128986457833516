import React, { useState } from "react";
import styled from "styled-components";
import {
  HeaderContainer,
  Logo,
  Nav,
  NavLink,
  HamburgerIcon,
  MobileMenu,
  CloseButton,
  ContactNumber,
  ContactNumberDesktop,
  DesktopNav,
  DesktopLink,
} from "./Header.styles";
import Hamburger from "../../Assets/Icons/hamburger.svg";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 5;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prevState) => {
      const newState = !prevState;
      document.body.style.overflow = newState ? "hidden" : "auto";
      return newState;
    });
  };

  const phoneNumber = "01962 717627";

  return (
    <>
      <HeaderContainer>
        <div className="OutsideWrapper">
          <Logo href="/">
            <img src="https://irp.cdn-website.com/39796b00/dms3rep/multi/inert.webp" alt="Company Logo" />
          </Logo>
          <ContactNumber href={`tel:${phoneNumber}`} style={{ textDecoration: "none", color: "inherit" }}>
            {phoneNumber}
          </ContactNumber>
          <HamburgerIcon src={Hamburger} alt="menu" onClick={toggleMenu} />

          <div className="NavigationContainer">
            <Nav>
              <NavLink href="/about">About us</NavLink>
              <NavLink href="/health-safety">Health &amp; Safety</NavLink>
              <NavLink href="/news">News</NavLink>
              <NavLink href="/contact">Contact us</NavLink>
            </Nav>
            <ContactNumberDesktop href={`tel:${phoneNumber}`} style={{ textDecoration: "none", color: "inherit" }}>
              {phoneNumber}
            </ContactNumberDesktop>
          </div>
        </div>

        {isOpen && (
          <>
            <Overlay isOpen={isOpen} onClick={toggleMenu} />
            <MobileMenu>
              <CloseButton onClick={toggleMenu}>X</CloseButton>
              <NavLink href="/about" onClick={toggleMenu}>
                About us
              </NavLink>
              <NavLink href="/health-safety" onClick={toggleMenu}>
                Health &amp; Safety
              </NavLink>
              <NavLink href="/news" onClick={toggleMenu}>
                News
              </NavLink>
              <NavLink href="/contact" onClick={toggleMenu}>
                Contact us
              </NavLink>
            </MobileMenu>
          </>
        )}
      </HeaderContainer>

      <DesktopNav>
        <DesktopLink href="/">Tipper Hire</DesktopLink>
        <DesktopLink href="/.">Plant Hire</DesktopLink>
        <DesktopLink href="/.">Land Restoration</DesktopLink>
        <DesktopLink href="/.">Landfill</DesktopLink>
        <DesktopLink href="/.">Waste Planning</DesktopLink>
      </DesktopNav>
    </>
  );
};

export default Header;
