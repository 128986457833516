import styled from "styled-components";

export const HealthAndSafetyContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 40px;
  background-color: #eef4f4;
  border-top: 2px solid rgb(171, 198,41);
`;

export const HealthAndSafetyWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const HealthAndSafetyTitle = styled.h1`
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

export const HealthAndSafetyCreditationsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const HealthAndSafetyCreditations = styled.img`
  max-width: 200px;
  height: 90px;
  margin: 7px;

  @media(max-width: 767px){
    max-width: 150px;
    height: unset;
  }
`;

export const HealthAndSafetyText = styled.p`
  width: 60%;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const CredentialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const HealthAndSafetyPageLink = styled.a`
cursor: pointer;
color: ${({ theme }) => theme.colors.darkBackground};
font-weight: 700;

&:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`