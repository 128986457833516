import styled from "styled-components";

export const PartnersContainer = styled.div`
    width: 100%;
    height: auto;
    text-align: center;
    border-bottom: 2px solid rgb(171, 198,41);
    padding: 10px;
    font-size: 32px;
`

export const PartnersWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 10px;
`
export const PartnerImage = styled.img`
    width: 100%;
    height: 150px;
    object-fit: contain;
`;

export const PartnersTitle = styled.h1`
  border-bottom: 2px solid rgb(171, 198,41);
  width: 15%;
  margin: 0 auto;
  margin-bottom: 25px;
  font-size: 32px;

@media(max-width: 767px){
    width: 70%;
    margin-bottom: 0px;
}
`