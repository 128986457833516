import React from "react";
import styled from "styled-components";

const PolicyContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;

  h1, h2 {
    color: #0a0a0a;
  }

  a {
    color: #4caf50;
    text-decoration: underline;
  }

  ul {
    margin-left: 20px;
  }
`;

const CookiePolicy = () => {
  return (
    <PolicyContainer>
      <h1>🍪 Cookie Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        At <strong>Inert Recycling (UK) Limited</strong>, we value your privacy and are committed to being 
        transparent about how we use cookies and similar technologies. This policy explains 
        what cookies are, how we use them, and how you can manage your preferences.
      </p>

      <h2>2. What Are Cookies?</h2>
      <p>
        Cookies are small text files stored on your device when you visit a website. 
        They help websites function properly, enhance user experience, and provide 
        analytical data to improve services.
      </p>

      <h3>Types of Cookies We Use</h3>
      <ul>
        <li><strong>Essential Cookies:</strong> Required for website functionality (e.g., login, security).</li>
        <li><strong>Performance Cookies:</strong> Track website usage to improve performance.</li>
        <li><strong>Functional Cookies:</strong> Store user preferences for a personalized experience.</li>
        <li><strong>Advertising Cookies:</strong> Used for targeted ads based on browsing behavior.</li>
      </ul>

      <h2>3. Why Do We Use Cookies?</h2>
      <p>We use cookies to:</p>
      <ul>
        <li>✅ Ensure our website functions correctly.</li>
        <li>✅ Remember your preferences and settings.</li>
        <li>✅ Analyze website traffic and performance.</li>
        <li>✅ Deliver personalized content and ads.</li>
      </ul>

      <h2>4. How to Manage Your Cookie Preferences</h2>
      <p>
        You can control and manage cookies through your browser settings. Most browsers allow you to:
      </p>
      <ul>
        <li>🔹 Accept or decline cookies automatically.</li>
        <li>🔹 Delete cookies stored on your device.</li>
        <li>🔹 Enable "Do Not Track" settings for additional privacy.</li>
      </ul>

      <h3>Browser-Specific Cookie Management:</h3>
      <ul>
        <li>🔹 <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">Chrome</a></li>
        <li>🔹 <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop" target="_blank" rel="noopener noreferrer">Firefox</a></li>
        <li>🔹 <a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer">Safari</a></li>
        <li>🔹 <a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank" rel="noopener noreferrer">Edge</a></li>
      </ul>

      <h2>5. Third-Party Cookies</h2>
      <p>Some cookies on our site may come from third-party services, such as:</p>
      <ul>
        <li>🔹 <strong>Google Analytics</strong> (to track website traffic)</li>
        <li>🔹 <strong>Social Media Integrations</strong> (e.g., Facebook, Twitter)</li>
        <li>🔹 <strong>Advertising Platforms</strong> (for targeted ads)</li>
      </ul>
      <p>
        These third-party services have their own cookie policies, which we encourage you to review.
      </p>

      <h2>6. Updates to This Policy</h2>
      <p>
        We may update this Cookie Policy from time to time to reflect changes in technology, 
        legislation, or our services. Any updates will be posted on this page with a revised "Last Updated" date.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about our Cookie Policy, feel free to contact us at:
      </p>
      <ul>
      <li>📧 <strong>Email:</strong> <a href="mailto:Keith@inertrecycling.co.uk">Keith@inertrecycling.co.uk</a></li>
        <li>📍 <strong>Address:</strong> Highbridge Estates, Brambridge, Eastleigh, SO50 6HS</li>
      </ul>

      <p><strong>Last Updated:</strong> 2025</p>
    </PolicyContainer>
  );
};

export default CookiePolicy;
