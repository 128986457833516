import styled from "styled-components";
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const CustomVerticalTimeline = styled(VerticalTimeline)`
  .vertical-timeline--two-columns .vertical-timeline-element .vertical-timeline-element-icon {
    height: 10px !important;
    width: 10px !important;
  }
  .vertical-timeline-element-subtitle{
    font-weight: 200;
  }
`;

export const TimeLineDescription = styled.p`
width: 100%;
margin: 0 auto;
padding: 15px;
@media (min-width: 1023px) {
    width: 60%;
  }
`
export const TeamImageWrapper = styled.div`
width: 100%;
padding: 10px;
padding-top: 0px;
`

export const TeamImage = styled.img`

@media (min-width: 768px) {
  width: 500px;
  margin: unset;
  };

  @media(min-width: 1070px){
    margin: 0 auto;
  }
`