import styled from "styled-components";
import newSimage from '../../Assets/Images/newsImage2.png'

export const HealthPageWrapper = styled.div``

export const HealthPage = styled.div`

`
export const FirstContentWrapper = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
`;

export const OverlayContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${(props) => props.backgroundimage}); 
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center; 
  justify-content: flex-start; 
  color: white; 
  .textWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }


  .textContentWrapper {
    display: flex;
    flex-direction: column;
    color: inherit;
    font-size: 42px;
    
    .textContent{
      font-size: 19px;
    }
    

    @media (max-width: 767px) {
      max-width: 340px;
    }
  }
  .secondaryTextContent{
    margin: 0;
  }
`;

export const FirstTextContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 25px;
`
export const TextContent = styled.p`
    max-width: 500px;
`
export const SecondTextContainer = styled.div`
    display: flex;
    width: 100%;
    height: 400px;
    background: lightgrey;
    @media(max-width: 768px){
        height: auto;
    }



`
export const SecondContainer = styled.div`
height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media(max-width: 768px){
    flex-direction: column;
    }
`
export const Image = styled.div`
    width: 50%;
    height: 100%;
    background-image: url(${newSimage});
    background-size: cover;
    background-position: center;
    @media(max-width: 768px){
        width: 100%;
        height: 200px;
    }
`;
export const Text = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 25px;
    flex-direction: column;
    justify-content: center;
    @media(max-width: 768px){
        width: 100%;
        p{
        }
    }
`
export const ThirdTextContainer = styled.div`
    background: #0a0a0a;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        max-width: 500px;
        text-align: center;
    }
    @media(max-width: 768px){
        p{
            padding: 10px 25px;
        }
        h1{
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 0rem;
        }

    }
`
export const CredentialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;

  @media (max-width: 767px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;
export const HealthAndSafetyCreditationsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const HealthAndSafetyCreditations = styled.img`
  width: auto;
  height: 120px;
  margin: 7px;
  @media(max-width: 767px){
    height: 100px;
  }
`;

export const ForthTextContainer = styled.div`
display: flex;
justify-content: center;
`