import styled from "styled-components";

export const TextInformationWrapper = styled.div`
height: 500px;
display: flex;
padding: 20px 40px;
gap: 40px;
@media(max-width: 1023px){
    flex-direction: column;
}
`

export const TextInformationCol = styled.div`
width: 50%;

@media(max-width: 1023px){
    width: 100%;
}
.unorderedList{
    list-style: inside;
}

`
export const InfoTitle = styled.h1`

`

export const ImageInfoCol = styled.div`
width: 50%;
border: 1px solid blue;
@media(max-width: 1023px){
    width: 100%;
}
`
export const SecondaryImagesWrapper = styled.div`
display: flex;
height: 30%;
`

export const SecondaryImages = styled.div`
    height: 100%;
    border: 1px solid yellow;
    width: calc(33% - 10px);
`

export const MainImg = styled.div`
height: 70%;
border: 1px solid red;
`

export const InformationText = styled.p`
font-weight: 400;
`