import React, { useRef } from "react";
import { FooterContainer, FooterWrapper, ColWrapper, FirstContent, SecondContent, ThirdContent, TermsInfo, Address, Contact, FooterLink, FooterTermsLink, Logo, AddressTitle,ImageContent } from "./Footer.styles";

const Footer = () => {
  const logoRef = useRef(null);

  const handleLogoClick = (e) => {
    e.preventDefault();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <FooterContainer>
      <FooterWrapper>
        <ColWrapper>
          <FirstContent>
            <h3>Inert Recycling (UK)</h3>
            <Address>
              <AddressTitle>Address:</AddressTitle>
              <p>Highbridge Estates,</p>
              <p>Brambridge,</p>
              <p>Eastleigh,</p>
              <p>SO50 6HS</p>
            </Address>

            <Contact>
              <p>
                Tel. <a href="tel:01962717627">01962 717627</a>
              </p>
              <p>
                E. <a href="mailto:Keith@inertrecycling.co.uk">Keith@inertrecycling.co.uk</a>
              </p>
              <p>Open Monday - Friday, 9am - 4:30pm</p>
            </Contact>
          </FirstContent>

          <SecondContent>
            <h3>Hire:</h3>
            <ul>
              <FooterLink href="/">Tipper Hire</FooterLink>
              <FooterLink href="/">Plant Hire</FooterLink>
              <FooterLink href="/">Surveying</FooterLink>
              <FooterLink href="/">Land Restoration</FooterLink>
            </ul>
          </SecondContent>
          <ImageContent>
          <img src="https://www.fors-online.org.uk/cms/wp-content/uploads/2019/04/BRONZE-FLEET-203x300.png" />
          </ImageContent>
          
          <ThirdContent>
            <Logo ref={logoRef} onClick={handleLogoClick}>
              <img src="https://irp.cdn-website.com/39796b00/dms3rep/multi/inert.webp" alt="Logo" />
              
            </Logo>
          </ThirdContent>
        </ColWrapper>
      </FooterWrapper>
      <TermsInfo>
        © 2024 Inert Recycling (UK) LTD | 
        <FooterTermsLink href="/cookie-policy"> Cookies Policy | </FooterTermsLink>
        <FooterTermsLink href="/">Legal Statement</FooterTermsLink>
      </TermsInfo>
    </FooterContainer>
  );
};

export default Footer;
