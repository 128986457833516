import styled from "styled-components";
import { Swiper as OriginalSwiper } from "swiper/react"; // Import the Swiper for styling

export const Wrapper = styled.div`
  width: 100%;
  height: 450px;
`;

export const FirstContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const StyledSwiper = styled(OriginalSwiper)`
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 767px) {
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination-bullets {
      display: none;
    }
  }
`;

export const OverlayContainer = styled.div`
  position: relative; /* Ensure the overlay text is positioned relative to this container */
  width: 100%;
  height: 100%;
  .textContentWrapper {
    position: absolute;
    top: 25%;
    left: 20%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      top: 60px;
      left: 30px;
      max-width: 340px;
    }
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%) brightness(0.5) contrast(1);
`;

export const OverlayText = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 42px;
  line-height: 1.2;
  margin-right: 10px;
  z-index: 10000;

  @media (min-width: 768px) {
    max-width: 550px;
  }
`;

export const OverlayTextDescription = styled.p`
  color: ${({ theme }) => theme.colors.body};
  margin-right: 10px;
  overflow-wrap: anywhere;
  width: inherit;
  max-width: 320px;
`;

export const SegmentButton = styled.a`
  width: 155px;
  font-weight: 700;
  background: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.darkBackground};
  padding: 10px 25px;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.colors.body};
  }
`;
