import React from "react";

import { HealthAndSafetyContainer, HealthAndSafetyWrapper, HealthAndSafetyTitle, HealthAndSafetyCreditationsWrapper, HealthAndSafetyCreditations, HealthAndSafetyText, CredentialWrapper,HealthAndSafetyPageLink } from "./health_and_safety.styles";

const HealthAndSafety = () => {
  return (
    <HealthAndSafetyContainer>
      <HealthAndSafetyWrapper>
        <HealthAndSafetyTitle>Health and Safety Accreditations</HealthAndSafetyTitle>
        <HealthAndSafetyCreditationsWrapper>
          <HealthAndSafetyText>
            For anyone working in the construction industry, health and safety is a top priority. Cutting corners is never an option, and maintaining the highest standards is essential. A strong commitment to safety should be embedded in the culture and embraced by every team member.
          </HealthAndSafetyText>
          <CredentialWrapper>
          {/* <HealthAndSafetyCreditations src="https://www.fors-online.org.uk/cms/wp-content/uploads/2019/04/BRONZE-FLEET-203x300.png" alt="Credential 1" /> */}
          <HealthAndSafetyCreditations src="https://www.safetypassports.co.uk/wp-content/uploads/2013/02/cropped-SPA-Banner-Logo.png" alt="Credential 2" />
          <HealthAndSafetyCreditations src="https://cdn-res.keymedia.com/cms/images/us/069/0343_638477691031814524.png" alt="Credential 3" />
          <HealthAndSafetyCreditations src="https://i.imgur.com/L7Kjt4T.png" alt="Credential 4" />
          </CredentialWrapper>
          <HealthAndSafetyPageLink href="/health-safety">Read More</HealthAndSafetyPageLink>
        </HealthAndSafetyCreditationsWrapper>
      </HealthAndSafetyWrapper>
    </HealthAndSafetyContainer>
  );
};


export default HealthAndSafety;
