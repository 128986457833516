import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  border: 1px solid #ddd;

`;

export const ImageContainer = styled.div`
  flex-shrink: 0;
  width: 35%;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

export const TextContainer = styled.div`
  h2 {
    font-size: 3.5rem; /* 20px */
    font-weight: bold;
    margin-bottom: 8px;
  }

  p {
    font-size: 1rem;
    color: #555;
  }
`;
export const InfoContainer = styled.div`
    margin: 0 auto;
    text-align: center;
`

export const InfoTitle = styled.h1`
    margin: 0;
`

export const InfoCardWrapper = styled.div`
    margin: 0 auto;
    width: 90%;
    padding: 20px;
    display: flex;

`

export const InfoCard = styled.div`
    width: 250px;
    height: 250px;
    border: 1px solid red;
`
export const Card = styled.div`
    //border: 1px solid red;
    display: flex;
    width: calc(33% - 10px);
    flex-direction: column;
`


export const CardImage = styled.img`
    width: 100%;
    //height: 100px;
`

export const CardText = styled.p`
margin-bottom: 0px;
`