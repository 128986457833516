import styled from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 450px;
  margin: auto;
  margin-bottom: 25px;
  min-height: 40px;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 25px;
    border-top: 1px solid black;
    padding-top: 25px;
  }
`;

export const FormField = styled.div`
  margin-bottom: 20px;
  width: 45%;
`;
export const FormFieldMessage = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  display: block;
  display: flex;
  align-items: center;
  margin-bottom: -6px;
  .requiredField {
    color: ${({ theme }) => theme.colors.secondary};
  }
  svg {
    margin-right: 10px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  border-top: none;
  border-left: none;
  border-right: none;
  background: #f4f4f4;
  outline: none;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: -webkit-fill-available;
  background: #f4f4f4;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: black;
  padding: 10px 15px;
  font-size: 16px;
  width: 155px;
  color: #0a0a0a;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 6px;
  }

  &:hover {
    color: white;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextField = styled.div`
  margin-bottom: 20px;
  @media(max-width: 1023px){
    width: 45%;
  }
`;
export const ContactTitle = styled.h1`
  font-size: 26px;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  height: 50px;
  width: 100%;
  align-items: center;
  text-align: center;
`;

export const ContactFormWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 50px;
  gap: 20px;

  @media (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
    padding: 20px 40px;
  }
`;
export const ContactDetails = styled.div`
  width: calc(45% - 10px);

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
`;
export const IconWrapper = styled.div`
  position: absolute;
  left: 10px; 
  top: 50%;
  transform: translateY(-50%); 
  pointer-events: none;
`;
export const ContactsWrapper = styled.ul``;

export const ContactWrapperList = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  svg {
    margin-right: 5px;
  }

  @media(max-width: 767px){
    display: block;
    svg{
      margin-top: -25px;
    }
  }
  a{
    font-weight: 700;
  }
  a{
  &:hover{
    color: ${({ theme }) => theme.colors.primary};

}
  }
`;
export const ContactTitleFirst = styled.h1`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
`;

export const ContactQuestionTitle = styled.p`
  font-size: 34px;
  font-weight: 600;
  line-height: 45px;
`;

export const ContactParagraph = styled.p``;


export const TelephoneWrapper = styled.a`
font-weight: 700;

&:hover{
  color: ${({ theme }) => theme.colors.primary};
}
`