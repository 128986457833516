import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 350px;
`;

export const FirstContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const OverlayContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
    url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center; 
  justify-content: flex-start; 
  color: white; 
  .textWrapper{
    display: flex;
    flex-direction: column;
    margin-left: 10%; 
  }


  .textContentWrapper {
    display: flex;
    flex-direction: column;
    color: inherit;
    font-size: 42px;
    .textContent{
      font-size: 19px;
    }
    

    @media (max-width: 767px) {
      max-width: 340px;
    }
  }
  .secondaryTextContent{
    margin: 0;
  }
`;


export const TextWrapper = styled.div`
  height: 400px;
  background: red;

`