import React, { useState } from "react";
import { FormContainer, FormField, Input, TextArea, Button, Label, ErrorMessage, RowWrapper, TextField, ContactFormWrapper, ContactDetails, FormFieldMessage,ContactsWrapper,ContactWrapperList,ContactTitleFirst, ContactQuestionTitle,ContactParagraph,TelephoneWrapper } from "./contact.styles";
import { FaLocationDot } from "react-icons/fa6";
import { MdCall } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { IoPersonCircleOutline } from "react-icons/io5";
import { CiCircleInfo } from "react-icons/ci";
import { FaPencil } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    honeypot: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const numericValue = value.replace(/\D/g, ""); // Remove any non-numeric characters
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.firstName) formErrors.firstName = "First Name is required";
    if (!formData.lastName) formErrors.lastName = "Last Name is required";

    if (!formData.phone) {
      formErrors.phone = "Phone number is required";
    } else {
      // UK phone number validation
      const ukPhoneRegex = /^(?:0|\+44)(?:\d\s?){9,10}$/;
      if (!ukPhoneRegex.test(formData.phone)) {
        formErrors.phone = "Invalid UK phone number";
      }
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      formErrors.email = "Invalid email address";
    }

    // if (!formData.subject) formErrors.subject = 'Subject is required';
    if (!formData.message) formErrors.message = "Message is required";

    if (formData.honeypot) formErrors.honeypot = "Spam detected"; // Honeypot trap

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const response = await fetch("https://formspree.io/f/xovagkgj", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          email: formData.email,
          //subject: formData.subject,
          message: formData.message,
          _gotcha: formData.honeypot, // Honeypot field for spam protection
        }),
      });

      if (response.ok) {
        setSubmitted(true); // Show success message
        setFormData({ firstName: "", lastName: "", phone: "", email: "", message: "", honeypot: "" }); 
      } else {
        console.error("Error submitting form");
      }
    } else {
      console.log("Form contains errors");
    }
  };

  return (
    <>
      <ContactFormWrapper>
        <ContactDetails>
          <ContactTitleFirst>Contact us</ContactTitleFirst>
          <ContactQuestionTitle>
            Have questions?<br></br>Get in touch!
          </ContactQuestionTitle>
          <ContactParagraph>Whether you need assistance, have inquiries about our machinery, or want to explore the best solutions for your tasks, feel free to reach out to us. Our team is always ready to provide the information and support you need.</ContactParagraph>
          <ContactsWrapper>
            <ContactWrapperList>
              <FaLocationDot />
              Highbridge Estates, Brambridge, Eastleigh, SO50 6HS
            </ContactWrapperList>
            <ContactWrapperList>
              <MdCall /> <TelephoneWrapper href="tel:01962717627">01962 717627</TelephoneWrapper>
            </ContactWrapperList>
            <ContactWrapperList>
              <MdOutlineMail />
              <a href="mailto:Keith@inertrecycling.co.uk">Keith@inertrecycling.co.uk</a>
            </ContactWrapperList>
          </ContactsWrapper>
        </ContactDetails>

        {!submitted ? (
          <FormContainer onSubmit={handleSubmit}>
            <RowWrapper>
              <TextField>
                <Label>
                  <IoPersonCircleOutline />
                  Name<span className="requiredField">*</span>
                </Label>
                <Input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                {errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
              </TextField>

              <FormField>
                <Label>
                  <MdOutlineMail />
                  Email<span className="requiredField">*</span>
                </Label>
                <Input type="email" name="email" value={formData.email} onChange={handleChange} />
                {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
              </FormField>
            </RowWrapper>

            <RowWrapper>
              <FormField>
                <Label>
                  <MdCall />
                  Phone<span className="requiredField">*</span>
                </Label>
                <Input type="text" name="phone" value={formData.phone} onChange={handleChange} pattern="\d*" title="Please enter a valid phone number" />
                {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
              </FormField>

              <FormField>
                <Label>
                  <CiCircleInfo />
                  Subject<span className="requiredField">*</span>
                </Label>
                <Input type="text" name="subject" value={formData.subject} onChange={handleChange} />
                {errors.subject && <ErrorMessage>{errors.subject}</ErrorMessage>}
              </FormField>
            </RowWrapper>

            <FormFieldMessage>
              <Label>
                <FaPencil />
                Message<span className="requiredField">*</span>
              </Label>
              <TextArea name="message" value={formData.message} onChange={handleChange} />
              {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
            </FormFieldMessage>

            <input type="text" name="honeypot" value={formData.honeypot} onChange={handleChange} style={{ display: "none" }} />

            <Button type="submit"><FiSend/>Get in Touch</Button>
          </FormContainer>
        ) : (
          <p>Thank you! Your message has been sent.</p>
        )}
      </ContactFormWrapper>
    </>
  );
};

export default ContactUs;
