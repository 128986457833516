import React from 'react'
import {TextInformationWrapper, TextInformationCol, InfoTitle, ImageInfoCol, MainImg,SecondaryImagesWrapper, SecondaryImages,InformationText} from './Images.styles'

const ImageContent = ({InfoText, TitleText}) => {
  return (
    <TextInformationWrapper>
      <TextInformationCol>
        <span className="lineBreak"></span>
        <InformationText>{InfoText}</InformationText>
        <h1><span className=''>Offers</span></h1>
        <span className="lineBreak"></span>
        <ul className='unorderedList'>
          <li>First li</li>
          <li>Second li</li>
          <li>Third li</li>
          <li>Forth li</li>
          <li>Fifth li</li>
        </ul>
      </TextInformationCol>
      <ImageInfoCol>
        <MainImg></MainImg>
        <SecondaryImagesWrapper>
          <SecondaryImages></SecondaryImages>
          <SecondaryImages></SecondaryImages>
          <SecondaryImages></SecondaryImages>
          <SecondaryImages></SecondaryImages>
        </SecondaryImagesWrapper>
      </ImageInfoCol>
    </TextInformationWrapper>
  )
}

export default ImageContent