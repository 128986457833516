import styled from "styled-components";
import { MapContainer } from "react-leaflet";

export const MapWrapper = styled.div`
  height: 250px;
  width: 100%;
`;

export const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
`;
