import React from "react";
import { Wrapper, FirstContentWrapper, OverlayContainer, TextWrapper } from "./hire.styles";

const Hire = ({ Tipper, backgroundimage,textContent }) => {
  return (
    <Wrapper>
      <FirstContentWrapper>
        <OverlayContainer backgroundimage={backgroundimage}>
          <div className="textWrapper">
            <div className="textContentWrapper">{Tipper}</div>
            <span className="lineBreak"></span>
            <p className="secondaryTextContent">{textContent}</p>
          </div>
        </OverlayContainer>
      </FirstContentWrapper>
    </Wrapper>
  );
};

export default Hire;
