import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { PartnersContainer, PartnersWrapper, PartnerImage,PartnersTitle } from './Partners.styles';
import tipper from '../../Assets/Images/tipper.jpg';
import aboutUs from '../../Assets/Images/aboutUs.png';

const Partners = () => {
    const partners = [
        { id: 1, logo: tipper },
        { id: 2, logo: "https://i.imgur.com/L7Kjt4T.png" },
        { id: 3, logo: tipper },
        { id: 4, logo: aboutUs },
        { id: 5, logo: tipper },
    ];

    return (
        <PartnersContainer>
            <PartnersTitle>Partners</PartnersTitle>
         
            <PartnersWrapper>
                <Swiper
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 8,
                        disableOnInteraction: false, 
                        pauseOnMouseEnter: false, 
                    }}
                    speed={4500} 
                    loop={true}
                    slidesPerView={3}
                    spaceBetween={30}
                >
                    {partners.map((partner) => (
                        <SwiperSlide key={partner.id}>
                            <PartnerImage src={partner.logo} alt={`Partner ${partner.id}`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </PartnersWrapper>
        </PartnersContainer>
    );
};

export default Partners;
