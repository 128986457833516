import styled from "styled-components";

export const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBackground};
  border-top: 10px solid ${({ theme }) => theme.colors.primary};
  padding: 20px 40px;
  color: white;
  position: absolute;
  width: 100%;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column !important;
  }
`;

export const FirstContent = styled.div`
  flex: 1;
  h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.primary};
  }
  p {
    margin: 5px 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px !important;
  }
`;

export const Address = styled.div`
  margin-bottom: 10px;
`;

export const Contact = styled.div`
  p {
    margin: 3px 0;
    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.body};
      }
    }
  }
`;

export const SecondContent = styled.div`
  flex: 1;
  h3 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.body};
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    li {
      a {
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 20px !important;
  }
`;

export const ThirdContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor:pointer;
  
  @media(max-width: 767px){
    margin-top: 10px;
  }
`;

export const Logo = styled.a`
  margin-bottom: 20px;

  img {
    height: 70px;
  }
`;

export const TermsInfo = styled.div`
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  p {
    margin: 0;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightGray};
    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const FooterLink = styled.a`
  color: ${({ theme }) => theme.colors.body};
  border-bottom: none;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary}!important;
  }
`;

export const FooterTermsLink = styled.a`
  color: ${({ theme }) => theme.colors.body};
  border-bottom: none;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const AddressTitle = styled.h3`
color: white!important;
font-size: 20px!important;
text-transform: uppercase;
`
export const ImageContent = styled.div`
img{
  height: 150px;
}

@media(max-width: 767px){
  border-bottom: 1px solid white;
    padding: 0px 0px 10px 0px;
}
`