import React from "react";
import {Container,ImageContainer,TextContainer, InfoContainer, InfoTitle,InfoCardWrapper, Card, CardImage, CardText} from './machineryTypes.styles'

const InfoCard = ({ imageSrc, altText, text }) => {
    return (
      <Card>
        <CardImage src={imageSrc} alt={altText} />
        <CardText>{text}</CardText>
      </Card>
    );
  };

const MachineryTypes = ({ imageSrc, altText, title, description,infoCards }) => {
    return (
        <>
      <Container>
        <ImageContainer>
          <img src={imageSrc} alt={altText} />
        </ImageContainer>
        <TextContainer>
          <h2>{title}</h2>
          <p>{description}</p>
        </TextContainer>
      </Container>
      <InfoContainer>
        <InfoTitle>View Our Plant Machinery For Hire</InfoTitle>
        <InfoCardWrapper>
        {infoCards.map((card, index) => (
            <InfoCard
              key={index}
              imageSrc={card.imageSrc}
              altText={card.altText}
              text={card.text}
            />
          ))}
        </InfoCardWrapper>
      </InfoContainer>
      </>
    );
  };

export default MachineryTypes;
