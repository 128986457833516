const theme = {
  colors: {
    body: "#f4f4f4",
    text: "#333",
    primary: "rgb(171, 198, 41)",
    secondary: "red",
    darkBackground: "#0a0a0a",
  },
};

export default theme;
