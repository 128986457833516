import styled from "styled-components";
import Slider from "react-slick";

export const CustomSlider = styled(Slider)`
  .slick-next:before {
    color: black !important;
  }

  .slick-prev:before {
    color: black !important;
  }
  .slick-next{
    right: -20px;
  }
  .slick-prev{
    left: -20px;
  }
`;

export const MachineryContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px 40px;
`;

export const MachineryWrapper = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    display: flex;    
`;

export const MachineryInformationWrapper = styled.div`
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media(max-width: 768px){
        width: 100%;
        flex-direction: column;
    }
    @media (min-width: 768px) and (max-width: 1023px){
        width: 90%;
    }
`;

export const MachineryInformation = styled.a`
    position: relative; 
    width: calc(50% - 30px);
    height: 200px;
    margin-bottom: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; 
    cursor: pointer;
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease-in-out; 

    &:hover {
        transform: scale(1.01); 
    }

    &::after {
        content: '';
        position: absolute;
        top: 0; 
        right: 0; 
        width: 0;
        height: 0;
        border-left: 70px solid transparent; 
        border-bottom: 70px solid ${({ theme }) => theme.colors.primary};
        rotate: 270deg;
    }

    @media(max-width: 768px) {
        width: 100%;
    }
`;


export const MachineryTitle = styled.h1`
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative; 
    z-index: 2; 
`;

export const TriangleText = styled.div`
    color: white;
    z-index: 1;
    text-align: center; 
    font-weight: 700;
`;



