import styled from "styled-components";



export const NewsOutsideWrapper = styled.div``;

export const NewsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 25px;
  flex-wrap: wrap;
`;

export const NewsElement = styled.a`
  width: calc(33% - 20px);
  height: 360px;
  overflow: hidden;
  color: unset;
  transition: unset;
  cursor: pointer;
  border: 1px solid lightgrey;
  padding: 5px;

  &:hover {
    color: unset;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: calc(50% - 10px)
  }
`;

export const NewsImage = styled.img`
  height: 50%;
  width: 100%;
  background: ${({ imageurl }) => `url(${imageurl}) no-repeat center/cover`};
`;

export const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: space-between;
  border: 1px solid lightgrey;
  padding: 5px;
`;

export const NewsTitle = styled.h1`
  font-size: 20px;
  text-align: start;
  font-weight: 600;
  margin: 0;
`;

export const NewsDescription = styled.p`
  font-size: 14px;
  font-weight: 100;
  margin: 0;
  text-align: start;
`;

export const NewsBottomContent = styled.div`
  display: flex;
  align-items: center;
  font-weight: 100;
  justify-content: space-between;
`;

export const NewsSpan = styled.span`
  display: flex;
  align-items: center;
  margin-right: 5px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 8px;
  align-items: center;
`;

export const NewsDate = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

export const NewsMainTitle = styled.h1`
margin: 0 auto;
text-align: center;
margin-top: 25px;
`

export const NewsRight = styled.div`
display: flex;
`

export const NewsLeft = styled.div`

`

export const NewsReadMore = styled.a`
text-decoration: underline;
&:hover{
  color: ${({ theme }) => theme.colors.primary};
}
`