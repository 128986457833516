import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { CustomVerticalTimeline, TimeLineDescription, TeamImageWrapper, TeamImage } from "./timeLine.styles";

const TimeLine = () => {
  return (
    <>
      <TimeLineDescription>
      Since 2011, we have experienced remarkable growth, expanding our fleet and enhancing our plant availability while gaining access to multiple disposal sites. Today, we proudly stand as the leading haulage company on the south coast, operating a fleet of over 30 vehicles, which enables us to tackle projects that many others cannot. Our commitment to excellence and sustainability continues to drive us as we build on our legacy and look to the future.
      </TimeLineDescription>
      <CustomVerticalTimeline>
        <VerticalTimelineElement className="vertical-timeline-element--work" contentStyle={{ background: "#fff", color: "black" }} contentArrowStyle={{ borderRight: "7px solid  white" }} date="1993 - 2013" iconStyle={{ background: "rgb(171, 198, 41)", color: "#fff" }}>
          <h3 className="vertical-timeline-element-title">Trucks Limited</h3>
          <h5 className="vertical-timeline-element-subtitle">Southampton</h5>
          <p>Haulage Company</p>
          <p>Founded as a modest haulage company, steadily grew over the years, expanding its fleet and services.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement className="vertical-timeline-element--work" date="2013 - " iconStyle={{ background: "rgb(171, 198, 41)", color: "#fff" }}>
          <h3 className="vertical-timeline-element-title">Inert Recycling (UK) Limited</h3>
          <h5 className="vertical-timeline-element-subtitle">Ringwood Hampshire</h5>
          <p>Haulage Company</p>
          <p>By 2013, we become a trusted name in tipper hire, plant hire, land restoration, landfill management, and waste planning, serving a wide range of industries with reliability and expertise. </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement className="vertical-timeline-element--education" date="2013 - " iconStyle={{ background: "rgb(171, 198, 41)", color: "#fff" }}>
          <h3 className="vertical-timeline-element-title">Inert Recycling (UK) Limited</h3>
          <h5 className="vertical-timeline-element-subtitle">Ashley Crescent, Southampton</h5>
          <p>Haulage Company</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement className="vertical-timeline-element--work" date="2013 - Present" iconStyle={{ background: "rgb(171, 198, 41)", color: "#fff" }}>
          <h3 className="vertical-timeline-element-title">Inert Recycling (UK) Limited</h3>
          <h5 className="vertical-timeline-element-subtitle">Highbridge Estates, Eastleigh</h5>
          <p>Provides reliable tipper hire, plant hire, land restoration, landfill management, and waste planning services, ensuring efficient haulage solutions for construction and environmental projects. </p>
        </VerticalTimelineElement>
        <style>
          {`
      .vertical-timeline--two-columns .vertical-timeline-element .vertical-timeline-element-icon {
        height: 10px !important;
        width: 10px !important;
      }

      @media (min-width: 1170px) {
        .vertical-timeline--two-columns .vertical-timeline-element .vertical-timeline-element-icon {
          margin-left: -5px;
        }
      }

      @media (max-width: 1169px) {
        .vertical-timeline--two-columns .vertical-timeline-element .vertical-timeline-element-icon {
          margin-left: 15px;
        }
      }
    `}
        </style>
      </CustomVerticalTimeline>
      <TeamImageWrapper>
        <TeamImage src={require("../../Assets/Images/aboutUs.png")} alt="Team" />
      </TeamImageWrapper>
    </>
  );
};

export default TimeLine;
