import React from "react";
import { SegmentContainer, SegmentWrapper, SegmentTitle, SegmentText, SegmentButton } from "./segment.styles";

const Segment = () => {
  return (
    <SegmentContainer>
      <SegmentWrapper>
        <SegmentTitle>Our Story</SegmentTitle>
        <SegmentText>
        Founded in 1999 as a modest haulage company, Inert Recycling (UK) Limited has evolved significantly over the years. Our journey began with Trucks Limited in 1993, and after 20 years of successful operation, we established Inert Recycling (UK) Limited in 2013. Over the years, we have had multiple office locations, with our final office now situated in Highbridge Estates, Eastleigh.
        </SegmentText>
        <SegmentButton href="/about">Read more</SegmentButton>
      </SegmentWrapper>
    </SegmentContainer>
  );
};

export default Segment;
