import React from 'react'
import {HealthPageWrapper,OverlayContainer,FirstContentWrapper,FirstTextContainer,TextContent,SecondTextContainer,SecondContainer,Text,Image,ThirdTextContainer,CredentialWrapper,HealthAndSafetyCreditations,ForthTextContainer} from "./Health_and_SafetyPage.styles"

const HealthAndSafetyPage = ({ backgroundimage }) => {
  return (
    <>
    <HealthPageWrapper>
        <FirstContentWrapper>
        <OverlayContainer backgroundimage={backgroundimage}>
          <div className="textWrapper">
            <div className="textContentWrapper">Health & Safety</div>
          </div>
        </OverlayContainer>
        </FirstContentWrapper>
        <FirstTextContainer>
            <TextContent>
            At Inert Recycling, health and safety are at the core of everything we do. Our commitment to a safe working environment ensures the well-being of our employees, customers, and the communities we serve. We strictly adhere to industry regulations and best practices to minimize risks and maintain a secure, sustainable recycling operation.
            </TextContent>
        </FirstTextContainer>
        <SecondTextContainer>
            <SecondContainer>
                <Text>
                    <p>From handling and processing inert materials to operating heavy machinery, we prioritize hazard awareness, proper training, and the use of personal protective equipment (PPE). Our team is continuously trained in workplace safety, emergency procedures, and environmental responsibility to prevent accidents and promote a culture of safety first.</p>
                    <p>By working together and following our strict health and safety guidelines, we ensure that Inert Recycling remains a responsible and trusted leader in inert recycling. Every member of our team plays a vital role in maintaining a safe workplace by adhering to established protocols and staying vigilant. We foster a culture where safety is everyone's responsibility, encouraging open communication and the reporting of potential hazards without hesitation.</p>
                </Text>
                <Image></Image>
            </SecondContainer>
        </SecondTextContainer>
        <ThirdTextContainer>
            <h1>Staying safe on sites across the South of England</h1>
            <p>Regular safety audits, risk assessments, and ongoing training programs help us identify and mitigate risks before they become issues. We are committed to continuously improving our processes, embracing new technologies, and implementing best practices to protect both our workforce and the environment.</p>
        </ThirdTextContainer>
        <ForthTextContainer>
            <CredentialWrapper>
          <HealthAndSafetyCreditations src="https://www.safetypassports.co.uk/wp-content/uploads/2013/02/cropped-SPA-Banner-Logo.png" alt="Credential 2" />
          <HealthAndSafetyCreditations src="https://cdn-res.keymedia.com/cms/images/us/069/0343_638477691031814524.png" alt="Credential 3" />
          <HealthAndSafetyCreditations src="https://i.imgur.com/L7Kjt4T.png" alt="Credential 4" />
            </CredentialWrapper>
        </ForthTextContainer>


    </HealthPageWrapper>
    </>
  )
}

export default HealthAndSafetyPage